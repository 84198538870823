@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes audioVisualizer {
  0% {
    height: 10%;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 10%;
  }
}

.audio-bar-animated {
  animation: audioVisualizer 0.5s ease infinite;
}

@keyframes checkmarkPopup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.animate-checkmark-popup {
  animation: checkmarkPopup 1s ease-out forwards;
}
